import { authorizedApiRequest } from '@/services/ApiService';

/**
 * Method to update user billing data
 *
 * @param profileId
 * @param data
 * @returns {Promise<AxiosResponse<*>>}
 */
export const updateBillingData = ({ profileId, data }) => {
  return authorizedApiRequest({
    method: 'PUT',
    endpoint: `/api/v1/users/${profileId}/invoiceinfo`,
    data,
  });
};
