import { authorizedApiRequest } from '@/services/ApiService';

/**
 * Method to remove profile payment card
 *
 * @returns {Promise<AxiosResponse<*>>}
 */
export const removePaymentCard = () => {
  return authorizedApiRequest({
    method: 'DELETE',
    endpoint: `/api/v1/users/paymentCard`,
  });
};
