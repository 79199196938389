<template>
  <v-row
    class="subscription-packages-item pa-2"
    align="stretch"
    justify="space-between"
    :style="`min-height: ${isMobileDevice ? '145px' : '321px'}`"
    no-gutters
  >
    <template v-if="isMobileDevice">
      <v-col cols="12" style="border-bottom: 1px solid #d4dff0">
        <v-container fill-height>
          <v-row align="center" justify="center" no-gutters>
            <v-col cols="12">
              <v-skeleton-loader type="text"></v-skeleton-loader>
              <v-skeleton-loader type="text"></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <v-col cols="12">
        <v-row class="py-2" align="center" justify="space-between" no-gutters>
          <v-col class="px-2">
            <v-skeleton-loader type="text"></v-skeleton-loader>
            <v-skeleton-loader type="text"></v-skeleton-loader>
            <v-skeleton-loader type="text"></v-skeleton-loader>
          </v-col>

          <v-col cols="auto">
            <v-skeleton-loader type="button"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
    </template>

    <template v-else>
      <v-col cols="12" class="pb-2" style="border-bottom: 1px solid #d4dff0">
        <v-row class="pa-2" align="center" justify="center">
          <v-col cols="12" class="pt-8 pb-0">
            <h2
              class="subscription-packages-item__price--package-price headline font-weight-bold text-center mb-0"
            >
              <v-skeleton-loader type="text"></v-skeleton-loader>
            </h2>
          </v-col>

          <v-col cols="12" class="pt-0 text-center">
            <span class="subtitle-2 font-weight-regular">
              <v-skeleton-loader type="text"></v-skeleton-loader>
            </span>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="py-5 text-center" style="border-bottom: 1px solid #d4dff0">
        <div class="subscription-packages-item__price--package-price title font-weight-bold">
          <v-skeleton-loader type="text"></v-skeleton-loader>
        </div>

        <div class="subtitle-2 font-weight-regular">
          <v-skeleton-loader type="text"></v-skeleton-loader>
        </div>

        <div class="subtitle-2 grey--text">
          <v-skeleton-loader type="text"></v-skeleton-loader>
        </div>
      </v-col>

      <v-col cols="12" class="py-5 text-center">
        <v-skeleton-loader width="100%" type="button"></v-skeleton-loader>
      </v-col>
    </template>
  </v-row>
</template>

<script>
export default {
  name: 'SubscriptionSelectionLoadingItem',
  computed: {
    isMobileDevice() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription-packages-item {
  background-color: #ffffff;
  border: 1px solid #d4dff0;
  border-radius: 8px;
  position: relative;

  &__favourite-package {
    border: 1px solid #33d380;
  }

  &__favourite-package--badge {
    position: absolute;
    top: -1rem;
  }

  &__price--package-price {
    color: #5e239e;
  }
}
</style>
