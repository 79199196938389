<template>
  <v-col class="subscription-payment-card">
    <v-row align="end" justify="start">
      <v-col class="col-auto px-0">
        <h2 class="headline font-weight-bold text-left mb-0">
          {{ $t('payments.card.title') }}
        </h2>
      </v-col>

      <v-col v-if="isPaymentCardAdded" class="col-auto px-1">
        <v-btn
          color="error"
          class="text-decoration-underline px-1"
          text
          small
          @click="removePaymentCard"
        >
          {{ $t('payments.card.delete') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row
      class="subscription-payment-card__card-detail pa-2 pa-sm-5 pa-md-10"
      align="center"
      justify="start"
    >
      <v-col cols="12">
        <h2 class="font-weight-medium mb-0" style="font-size: clamp(20px, 3vw, 32px)">
          {{ paymentCardNumber }}
        </h2>
      </v-col>

      <v-col cols="12">
        <v-row align="center" justify="start" no-gutters>
          <v-col class="col-6">
            <v-row align="center" justify="start" no-gutters>
              <v-col class="col-12 text-left grey--text">
                <span>{{ $t('general.valid_till') }}</span>
              </v-col>

              <v-col class="col-12 text-left">
                <span class="font-weight-medium">
                  {{ paymentCardValidity }}
                </span>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="col-6">
            <v-row align="center" justify="start" no-gutters>
              <v-col class="col-12 text-left grey--text">
                <span>{{ $t('payments.card.card_issuer') }}</span>
              </v-col>

              <v-col class="col-12 text-left">
                <span class="font-weight-medium">{{ paymentCardBrand }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="paymentsSubscriptionCanceledInfoDialog" max-width="500" width="100%">
      <v-card class="pa-0">
        <v-card-text class="pt-5 title black--text text-center">
          {{ $t('payments.subscription.cancel.info') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="paymentsSubscriptionCanceledInfoDialog = false">
            {{ $t('general.understand') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { removePaymentCard } from '@subscription/services/paymentCardService';

export default {
  name: 'SubscriptionPaymentCard',
  data() {
    return {
      paymentsSubscriptionCanceledInfoDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      userProfile: 'user/profile',
    }),
    isPaymentCardAdded() {
      return this.paymentCard?.card_number !== null;
    },
    paymentCard() {
      return this.userProfile?.payment_card;
    },
    paymentCardNumber() {
      const cardNumber = this.paymentCard?.card_number || 'XXXXXXXXXXXXXXXX';

      return cardNumber.replace(/(.{4})/g, '$1 ');
    },
    paymentCardBrand() {
      return this.paymentCard?.card_brand || 'XXXX';
    },
    paymentCardValidity() {
      const expirationDate = this.paymentCard?.card_expiration || 'XXXX';

      return `${expirationDate.substr(2)}/${expirationDate.substr(0, 2)}`;
    },
  },
  methods: {
    ...mapActions({
      fetchProfile: 'user/fetchProfile',
    }),
    removePaymentCard() {
      removePaymentCard()
        .then((resp) => {
          if (true === resp?.activeSubscription) {
            this.paymentsSubscriptionCanceledInfoDialog = true;
          }

          this.$notification.success(this.$t('payments.card.deleted'));
        })
        .catch(() => {
          this.$notification.error(this.$t('payments.card.delete_error'));
        })
        .finally(() => {
          this.fetchProfile();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription-payment-card {
  &__card-detail {
    background-color: #ffffff;
    border: 1px solid #d4dff0;
    border-radius: 8px;
  }
}
</style>
