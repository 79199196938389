<template>
  <v-row class="credit-packages-item pa-2" align="stretch" justify="space-between" no-gutters>
    <template v-if="isMobileDevice">
      <v-col cols="12" style="border-bottom: 1px solid #d4dff0">
        <v-container fill-height>
          <v-row align="center" justify="center" no-gutters>
            <v-col cols="12">
              <span
                v-if="creditPackage.credit_original"
                class="title font-weight-bold text-decoration-line-through"
                style="color: #d4dff0"
                >{{ creditPackage.credit_original }}</span
              >
              <span class="credit-packages-item__price--package-price title font-weight-bold">
                {{ creditPackage.credit }}
              </span>
              <span>
                {{
                  $t(
                    parseInt(creditPackage.credit) > 1
                      ? 'general.documents'
                      : 'contract.type.base_contract',
                  ).toLowerCase()
                }}
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <v-col cols="12">
        <v-row class="py-2" align="center" justify="space-between" no-gutters>
          <v-col class="pl-2">
            <div class="credit-packages-item__price--package-price title font-weight-bold">
              {{ creditPackagePrice }}
            </div>

            <div class="subtitle-2 grey--text">
              {{ oneCreditPackagePriceDescription }}
            </div>
          </v-col>

          <v-col cols="auto">
            <v-tooltip v-model="show" top :disabled="!isUnavailable">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn
                    color="primary"
                    :disabled="isUnavailable"
                    :loading="buyingCreditPackage"
                    @click="buyCreditPackage"
                  >
                    {{ $t('pricing.buy') }}
                  </v-btn>
                </div>
              </template>

              <span>{{ $t('subscription.message.unavailable') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </template>

    <template v-else>
      <v-col cols="4">
        <v-container fill-height>
          <v-row align="center" justify="center" no-gutters>
            <v-col cols="12">
              <span
                v-if="creditPackage.credit_original"
                class="title font-weight-bold text-decoration-line-through"
                style="color: #d4dff0"
                >{{ creditPackage.credit_original }}</span
              >
              <span class="credit-packages-item__price--package-price title font-weight-bold">
                {{ creditPackage.credit }}
              </span>
              <span>
                {{
                  $t(
                    parseInt(creditPackage.credit) > 1
                      ? 'general.documents'
                      : 'contract.type.base_contract',
                  ).toLowerCase()
                }}
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <v-col cols="3">
        <v-container fill-height>
          <v-row justify="center" align="center" no-gutters>
            <v-col cols="12">
              <img
                v-if="creditPackage.credit_original"
                src="/images/credits-christmas-50-cs.png"
                style="max-height: 32px !important"
                height="100%"
                width="auto"
                alt=""
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <v-col cols="5">
        <v-row class="pl-4" align="center" justify="space-between" no-gutters>
          <v-col class="pl-4">
            <div class="credit-packages-item__price--package-price title font-weight-bold">
              {{ creditPackagePrice }}
            </div>

            <div class="subtitle-2 grey--text">
              {{ oneCreditPackagePriceDescription }}
            </div>
          </v-col>

          <v-col cols="auto" class="px-4 text-center">
            <v-tooltip v-model="show" top :disabled="!isUnavailable">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn
                    color="primary"
                    class="px-10"
                    :disabled="isUnavailable"
                    :loading="buyingCreditPackage"
                    large
                    @click="buyCreditPackage"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $t('pricing.buy') }}
                  </v-btn>
                </div>
              </template>

              <span>{{ $t('subscription.message.unavailable') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { goPayPaymentInit } from '@subscription/services/paymentService';
import Environment from '@/config/environment';

export default {
  name: 'CreditPackageItem',
  props: {
    creditPackage: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      buyingCreditPackage: false,
      show: false,
    };
  },
  computed: {
    ...mapGetters({
      currentCurrency: 'currencies/currentCurrency',
    }),
    isUnavailable() {
      return 'CZK' !== `${this.currentCurrency(this.creditPackage.currency)?.code}`;
    },
    creditPackagePrice() {
      let packagePrice = this.creditPackage.price;

      if (packagePrice % 10 > 0) {
        packagePrice = packagePrice.toFixed(0);
      }

      const finalPrice = packagePrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

      return `${'0' === finalPrice ? this.creditPackage?.price?.toFixed(2) : finalPrice} ${
        this.currentCurrency(this.creditPackage.currency)?.symbol
      }`;
    },
    currency() {
      return this.currentCurrency(this.creditPackage.currency);
    },
    oneCreditPackagePriceDescription() {
      let price = this.creditPackage?.price / this.creditPackage?.credit;

      if (price % 10 > 0) {
        price = price.toFixed(
          'EUR' === this.currentCurrency(this.creditPackage.currency)?.code ? 2 : 0,
        );
      }

      return `${price} ${this.currency?.symbol} / ${this.$t(
        'contract.type.base_contract',
      ).toLowerCase()}`;
    },
    isMobileDevice() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    buyCreditPackage() {
      this.buyingCreditPackage = true;

      goPayPaymentInit({
        data: {
          credit_id: this.creditPackage.id,
          return_url: `${Environment.getAppUrl()}${
            this.$router.resolve({ name: 'subscription' }).href
          }`,
          additional_params: {
            credit: this.creditPackage.credit,
          },
        },
      })
        .then((resp) => {
          // eslint-disable-next-line no-undef
          _gopay.checkout(
            {
              gatewayUrl: resp.gw_url,
              inline: false,
            },
            () => {},
          );
        })
        .catch((errCode) => {
          if (422 === errCode) {
            this.$notification.warning(this.$t('payments.billing_data.warning'));

            return this.$router.push({
              name: 'subscription',
              query: { action: 'addInvoiceData' },
            });
          }

          this.$notification.error(this.$t('payments.transaction.failed'));
          this.buyingCreditPackage = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.credit-packages-item {
  background-color: #ffffff;
  border: 1px solid #d4dff0;
  border-radius: 8px;

  &__price {
    &--package-price {
      color: #5e239e;
    }
  }
}
</style>
