import { authorizedApiRequest } from '@/services/ApiService';
import router from '@/router';
import store from '@/store';

/**
 * Check of billing data before create a payment
 *
 * @returns {boolean}
 */
const areBillingDataIncomplete = async () => {
  const billingData = store.getters['user/profile'];
  const isCompany = billingData?.invoice_info?.invoice_organization_name;
  const ico = billingData?.invoice_info?.invoice_ico;
  const firstName = billingData?.invoice_info?.invoice_firstname;
  const lastName = billingData?.invoice_info?.invoice_lastname;

  if ((isCompany && !ico) || (!isCompany && (!firstName || !lastName))) {
    await router.push({
      name: 'subscriptionBillingData',
    });

    return false;
  }

  return true;
};

/**
 * Init of new payment via GoPay
 *
 * @param data
 * @returns {Promise<AxiosResponse<*>>}
 */
export const goPayPaymentInit = async ({ data }) => {
  if (await areBillingDataIncomplete()) {
    return authorizedApiRequest({
      method: 'POST',
      endpoint: '/api/v1/payments',
      data,
    });
  }
};

/**
 * Getter of payment status by payment id
 *
 * @param paymentId
 * @returns {Promise<AxiosResponse<*>>}
 */
export const getGoPayPaymentStatus = ({ paymentId }) => {
  return authorizedApiRequest({
    method: 'GET',
    endpoint: `/api/v1/payments/${paymentId}`,
  });
};

/**
 * Method to disable subscription
 *
 * @returns {Promise<AxiosResponse<*>>}
 */
export const disableSubscriptionPackage = () => {
  return authorizedApiRequest({
    method: 'POST',
    endpoint: '/api/v1/users/disablepackage',
  });
};
