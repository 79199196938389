<template>
  <v-autocomplete
    v-model="country"
    :label="$t('general.country')"
    :items="countriesList"
    :filter="customFilter"
    item-value="alpha3"
    item-text="name"
    outlined
  >
    <template v-slot:append>
      <v-icon v-if="required && !country" color="error"> mdi-alert-circle-outline </v-icon>
    </template>

    <template v-slot:no-data>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            {{ $t('contract.external_company.not_found1') }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CountrySelect',
  props: {
    value: String,
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      countriesList: 'countries/list',
    }),
    country: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  async created() {
    await this.fetchCountriesList();
  },
  methods: {
    ...mapActions({
      fetchCountriesList: 'countries/fetchCountriesList',
    }),
    customFilter(item, queryText) {
      const textOne = item.name.toLowerCase();
      const textTwo = item.alpha3.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },
  },
};
</script>

<style lang="scss" scoped></style>
